.product-detail-section {
  width: 90vw;
  margin: 1.5rem auto;
  display: flex;
  justify-content: center;
  gap: 1rem;

  .images-section {
    display: flex;
    width: 50%;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 1rem;
      .dot-container {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
        margin-top: 0.5rem;
        span {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #ccc;
          cursor: pointer;
        }

        .active {
          background-color: #333;
        }
      }
    }
    .selected-image {
      margin-left: 4rem;

      img {
        margin-top: 2rem;
      }
    }
  }
  .details-section {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    position: relative;

    h2 {
      font-size: 1.8rem;
      text-align: left;
      font-weight: 600;
    }
    .upload-and-name {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    .price-quantity {
      display: flex;
      align-items: center;
      gap: 1rem;
      .price {
        display: flex;
        align-items: center;
        gap: 0.4rem;
      }
      p:nth-child(1) {
        font-size: 2rem;
        font-weight: 600;
        color: #405e83;
      }
      p:nth-child(2) {
        font-size: 1rem;
        text-decoration: line-through;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    p:nth-child(2),
    p:nth-child(3) {
      font-weight: 300;
      opacity: 0.8;
      line-height: 1.5;
      text-align: left;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    .images-section {
      width: 100%;
      .hero-image {
        width: 100%;
        height: 60vh;
      }
    }
    .details-section {
      width: 100%;
      .upload-and-name {
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
        align-items: flex-start;
      }
      .buttons {
        flex-direction: column;
      }
    }
  }
}

.key-feature-banner {
  width: 100%;
  height: 15vh;
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    height: 60vh;
  }
}
.product-banner {
  height: 60vh;
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    height: 24vh;
  }
}
.bottle-banner {
  height: 50vh;
  background-size: cover;
  background-position: center;
  @media (max-width: 768px) {
    height: 16vh;
  }
}
.features-spec-section {
  width: 90vw;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.8rem;
    width: 80vw;
  }
  li {
    font-weight: 300;
    opacity: 0.8;
    line-height: 1.8;
  }
}

.products-card-section {
  width: 90vw;
  margin: 0 auto;
  h2 {
    text-align: center;
  }
  .container {
    margin-top: 1rem;
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
