* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.Main {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 700px) {
  .Main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }
}
a {
  text-decoration: none;
}

li {
  list-style: none;
}
