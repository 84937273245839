.primary-btn {
  background: #121212;
  width: fit-content;
  padding: 0.8rem 2.5rem;
  border-radius: 1.5rem;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  color: #fff;
  transition: all ease-in 300ms;
}
.primary-btn:hover {
  background: #121212;
}
@media (max-width: 768px) {
  .primary-btn {
    width: 100%;
    padding: 1rem 3rem;
    font-size: 1rem;
  }
}
