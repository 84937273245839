.App {
  background-color: rgb(244, 244, 247);
  display: flex;
  flex-direction: column;
}

.OurProducts {
  /* border: 1em solid white; */
  border-radius: 5px;
  background-color: #e6ddc6;
}

.ProductImages {
  display: flex;
  flex-direction: row;
  border-radius: 5em;
  justify-content: center;
}

.ProductImages > div > img {
  width: 90%;
  height: 20em;
}

.WhatsApp {
  height: 5em;
  width: 5em;
  position: fixed;
  z-index: 1;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.WhatsApp > img {
  height: 100%;
  width: 100%;
}

.WhatsApp:hover {
  cursor: pointer;
}

button {
  background-color: white;
  color: black;
  border: 2px solid #080808;
  width: 10em;
  padding: 0.8em 1.5em;
  border-radius: 15px;
  margin: 1em;
}

button:hover {
  cursor: pointer;
  color: white;
  background-color: rgb(162, 207, 247);
  border-radius: 15px;
}

h3 {
  font-family: "muli";
  margin-top: 10px;
  font-weight: 800;
  font-size: 29px;
}

h2 {
  /* font-family: "Audiowide", sans-serif; */
  font-family: "Courier New";
  /* font-feature-settings: 'zero' 1; */
  /* font-variant-numeric: slashed-zero; */

  text-align: center;
  letter-spacing: 3px;
  line-height: 1.21;
  color: black;
  margin-left: 0px;
  margin-right: 0px;
  font-size: 55px;
  font-weight: 400;
  /* margin-top: .8em; */
}

p {
  font-family: Roboto, sans-serif;
  /* border: 2px solid gray;
  height: 15em; */
  font-family: Montserrat;
  text-align: center;
  /* letter-spacing: 0.0187rem; */
  /* word-spacing: 0.1875rem; */
  color: black;
  padding-right: 0.625rem;
  opacity: 1;
  font-size: 1rem;
  line-height: 1.7rem;
  font-weight: 100;
}

/* CSS */
.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #fa8888,
    #f7ba87,
    #f6f49d,
    #b0f893,
    #7ef2df,
    #92a4fa,
    #bc8bf0,
    #f888e0,
    #f08c8c
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@media only screen and (max-width: 700px) {
  .OurProducts {
    border: 0em solid white;
    /* border-top: .5em solid white; */
    border-radius: 5px;
    /* height: 20em; */
  }

  .ProductImages {
    display: flex;
    flex-direction: row;
    border-radius: 5em;
    justify-content: center;
    padding-right: 1em;
  }

  .ProductImages > div > img {
    width: 100%;
    height: 100%;
  }

  button {
    background-color: white;
    color: black;
    border: 2px solid #080808;
    width: 10em;
    padding: 0.8em 1.5em;
    border-radius: 5px;
  }

  button:hover {
    cursor: pointer;
    background-color: blue;
    border-radius: 5px;
  }

  h2 {
    font-family: sans-serif;

    text-align: center;
    text-align: center;
    letter-spacing: 0px;
    line-height: 1.21;
    color: black;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 28px;
    font-weight: bold;
    margin-top: 0.2em;
  }

  h3 {
    font-family: sans-serif;

    text-align: center;
    text-align: center;
    letter-spacing: 0px;
    line-height: 1.21;
    color: black;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 0.2em;
  }

  p {
    text-align: center;
    letter-spacing: 0px;
    /* line-height: 1.21; */
    color: black;
    margin-left: 0px;
    margin-right: 0px;
    font-family: sans-serif;
    font-size: 1rem;
  }
}
