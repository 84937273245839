.Header {
  background-color: aliceblue;
  display: flex;
  flex-direction: row;
  margin: 0%;
  padding: 1rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* z-index: 3; */
}

.Tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

a {
  transition: 5s;
}

.Tabs > div {
  padding: 0.4em;
}

a {
  color: black;
}

.Tabs > div > a:hover {
  color: blue;
  cursor: pointer;
}

.LogoName {
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  padding-left: 2em;
  font-size: 0.8em;
  padding-bottom: 0%;
}

.LogoName > p {
  padding: 0px;
  margin: 0px;
  width: 100%;
  margin-top: 3px;
  font-size: 0.97em;
}

.LogoName > img {
  height: 4em;
}

.Space {
  /* flex-grow: 8; */
}

@media only screen and (max-width: 600px) {
  .Header {
    display: flex;
    flex-direction: column;
    margin: 0%;
    padding: 0;
    padding-top: 0.8em;
    height: 3.4em;
    justify-content: center;
  }
  .Header {
    background-color: aliceblue;
    display: flex;
    flex-direction: row;
    margin: 0%;
    padding: 0;
    width: 100%;
    height: 5em;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    z-index: 3;
    padding-top: 0;
  }

  .Tabs {
    display: none;
    flex-direction: column;
    justify-content: space-around;
  }

  .LogoName {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding-top: 1em; */
    width: 100%;
    /* padding-left: 2em; */
    font-size: 0.8em;
    padding-bottom: 0%;
  }

  .LogoName > p {
    padding: 0px;
    margin-top: 1px;
    font-size: 0.85em;
    text-align: center;
  }

  .LogoName > img {
    height: 2.2em;
    width: 12em;
    margin-left: 0%;
  }
}
