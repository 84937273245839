.ProductMain {
    display: flex;
    flex-direction: row;
    /* background-color: rgb(119, 118, 118); */
    margin: 2em;
    padding: 1em;
}



.Product {
    
   
}

.Product:hover {
    /* transform: scale(1.01); */
    /* box-shadow: #faf8f5; */

    /* box-shadow: 10px 10px 5px #413b2e; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
    animation: example 4s linear infinite;
    /* background: linear-gradient(45deg,
            #fab8b8,
            #f1c098,
            #f3f294,
            #a1ed83,
            #7df4e0,
            #8fa0f6,
            #be8ef2,
            #f38bdd,
            #f89292);
    transition: opacity 3s ease-in-out; */
}





@media only screen and (max-width: 700px) {
    .ProductMain {
        display: flex;
        flex-direction: row;
        /* background-color: #A19882; */
        margin: 0em;
        padding: 0em;
    }



    .Product {
        font-family: Roboto, sans-serif;
        padding: 1em solid white;
        padding: 1em;
        max-width: 100%;
      
    }

    img {
        max-width: 100%;
    }

}