.Footer {
    padding: 0px;
    margin: 0px;
    height: 60vh;
    display: flex;
    flex-direction: column;
    background-color: #121212;

}
.contact-us {
    margin-top: 2rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    box-shadow: 2px;
    background-color: #121212;
    color: white;
}

.contact-details {
    display: flex;
    padding: 2px;
    margin-top: 6rem;
    justify-content: space-around;

}
.copyright {
    padding: 1em;
    text-align: center;
    margin-top: 6rem;

}
.Locations{
    display: flex;
    padding: 2px;
    margin: 1em 15em;
    justify-content: space-around;
}
.social-icons {
    margin-top: 10px;
  }
  
  .social-icons a {
    margin: 0 10px;
    color: white;
    font-size: 24px;
  }
  
  .social-icons a:hover {
    color: #00aced; /* Change color on hover (Twitter color) */
  }
    
#map {
    height: 400px; /* The height is 400 pixels */
    width: 100%; /* The width is the width of the web page */
  }
@media only screen and (max-width: 600px) {
    .contact-details {
        display: flex;
        flex-direction: column;
        padding: 10px;
        justify-content: space-around;

    
    }
    
  }
