/* ProductDetails.css
img{
  width: 30em;
} */

.Description {
  font-weight: 400;
  text-align: left;
  font-family: auto;
  font-size: 16px;
}
.product-details {
  display: flex;
  align-items: flex-start;
  padding: 0.5em;
}

.product-images {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selected-image {
  overflow: hidden;
  /* border: 1px solid #ddd; */
  max-width: 60%;
}

.selected-image > img {
  max-width: 100%;
  display: block;
  margin: auto;
}

/* .selected-image .carousel-root {
    width: 100%;
    height: 100%;
  } */

.thumbnail-images {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 10px;
  flex-wrap: wrap;
}

.thumbnail-images img {
  max-width: 5em;
  max-height: 5em;
  cursor: pointer;
  margin: 0 5px;
  border: 1px solid #ddd;
  padding: 5px;
}

.thumbnail-images img.active {
  border: 2px solid black;
}

.product-info {
  flex: 1.1;
  padding-left: 20px;
  text-align: center;
}
.product-buy {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.color-selector {
  display: flex;
  margin-bottom: 10px;
}

.color {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
}

.color.selected {
  border: 2px solid black;
}

button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.1rem;
}

button:hover {
  background-color: black;
}

@media only screen and (max-width: 700px) {
  /* ProductDetails.css */

  .product-details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product-images {
    align-items: center;
  }

  .selected-image {
    max-height: 100%;
    overflow: hidden;
    border: 1px solid #ddd;
  }

  .thumbnail-images {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    flex-wrap: wrap;
  }

  .thumbnail-images img {
    max-width: 40px;
    max-height: 40px;
    cursor: pointer;
    margin: 0 2px;
    border: 1px solid #ddd;
    padding: 2px;
  }

  .thumbnail-images img.active {
    border: 2px solid black;
  }

  .product-info {
    flex: 1;
    padding-left: 20px;
  }

  .color-selector {
    display: flex;
    margin-bottom: 10px;
  }

  .color {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
  }

  .color.selected {
    border: 2px solid black;
  }

  button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }
}
