/* GalleryStrip.css */

.gallery-strip {
    max-width: 100%;
    overflow: hidden;
    /* margin: 2em;
    padding: 1em; */
  }
  
  .gallery-strip .carousel .slide img {
    height: auto;
  }
  .carousel-images{
    flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .carousel-images img{
    flex: 1 1;
    max-width: 20em;
    max-height: 20em;
  }


  .row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
  }
  
  /* Create four equal columns that sits next to each other */
  .column {
    -ms-flex: 25%; /* IE10 */
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .column {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
    }
  
  
@media only screen and (max-width: 700px) {



  .gallery-strip {
    max-width: 100%;
    overflow: hidden;
    /* margin: 2em;
    padding: 1em; */
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
  }
  
  .gallery-strip .carousel .slide img {
    height: auto;
  }
  .carousel-images{
    flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .carousel-images img{
    flex: 1 1;
    max-width: 20em;
    max-height: 20em;
  }
}