.About {
  display: flex;
  flex-direction: column;
  /* border: 1em solid white; */
  background-color: #121212;
  border-radius: 5px;
  justify-content: center;
  color: white;
  text-align: center;
  height: 90vh;
  p{
    color: white;
  }
  h2{
    color: white;
  }

}

.AboutCard{
  margin: 2em;
  padding: 1em;
  margin-top: 0em;
  padding-top: 0em;

}


.AboutImage {
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  /* margin-left: 0em; */
}

.AboutImage>div>img {
  height: 18em;
}

.AboutImage>div>img:hover {
  height: 18em;
  transform: scale(1.03);

}

@media only screen and (max-width: 700px) {

  .About {
    display: flex;
    flex-direction: column;
    /* border: 1em solid white; */
    background-color: #121212;
    border-radius: 5px;
    justify-content: center;
    text-align: center;
    height: 100%;

  }


  .AboutImage {
    /* padding: 1em; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    /* margin-left: 0em; */
  }

  .AboutImage>p {
    padding: 0%;
    margin: 0%;
  }

  .AboutImage>div>img {
    height: 10em;
    width: 10em;
  }

  .AboutImage>div>img:hover {
    height: 10em;
    width: 10em;
    transform: scale(1);

  }

}