.product-variant {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 12%;
  img {
    border: 1px solid #00000040;
    cursor: pointer;
    border-radius: 0.4em;
  }
  .selected {
    border: 2px solid rgb(23, 23, 79);
  }

  @media (max-width: 768px) {
    flex-direction: row;
  }
}
