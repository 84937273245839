.color-selector {
  display: flex;
  margin: 1rem 0;
}

.color {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
}

.color.selected {
  border: 2px solid #007bff;
}
