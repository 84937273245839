.no-items {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.checkout-section {
  display: flex;
  width: 90vw;
  margin: 2rem auto;

  .MuiButton-colorPrimary {
    color: white;
    background: #121212;
  }

  .MuiButton-colorPrimary:hover {
    background: #121212;
  }

  .checkout-receipt {
    // border: 1px solid #00000050;
    padding: 0 0.4rem;
    border-radius: 1em;
    flex: 0 0 32vw;
    i {
      cursor: pointer;
      color: #fd9b9b;
      font-size: 1.2rem;
    }
  }

  .cart-details {
    background: #c6c6ec80;
    border-radius: 0.8em;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    ul {
      .product-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin: 1rem 0;
        .item {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        .engraving {
          width: 70%;
          span {
            font-size: 0.8rem;
          }
          p {
            background: #ededed;
            padding: 0.6rem;
            color: #949494;
            font-weight: 700;
          }
        }
        .item-image {
          img {
            width: 4vw;
            border-radius: 0.4em;
          }
        }
        .item-quantity {
          .item-name {
            font-size: 1rem;
          }
          .price-quantity {
            display: flex;
            .price {
              color: #566b84;
              font-weight: 700;
            }
            .quantity {
              opacity: 0.5;
              font-weight: 500;
            }
          }
        }
      }
    }
    .bill-breakdown {
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      p {
        font-weight: 700;
      }
      .total {
        display: flex;
        justify-content: space-between;
      }
      .discount {
        display: flex;
        justify-content: space-between;
        opacity: 0.5;
      }
      .subTotal {
        border-top: 1px solid #00000050;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 1rem;
    .mobile-field {
      flex-direction: column;
    }
    .checkout-receipt {
      i {
        font-size: 1.5rem;
      }
      .cart-details {
        ul {
          .product-item {
            .item-image {
              img {
                width: 24vw;
              }
            }
            .item-quantity {
              .item-name {
                font-size: 0.7rem;
                font-weight: 600;
              }
              .price-quantity {
                .quantity {
                  font-size: 0.7rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
