.card-content {
  height: 60vh;
  border: 1px solid #ececec;
  padding: 1rem;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.8rem;
  transition: all 300ms ease-in;
  box-shadow: 1px 0 0.4rem gray;
  flex: 0 0 20vw;
  .product-image {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 9vw;
      @media (max-width: 768px) {
        width: 60vw;
      }
    }
  }
  .detail {
    // height: 30%;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    p{
      font-weight: 500;
    }

    .price {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      h3 {
        font-size: 1.5rem;
      }
      span {
        font-weight: 700;
      }
    }
    p {
      font-size: 1rem;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .button {
    // display: none;
    text-align: center;
    margin: 0.4rem 0;
    button {
      padding: 0.8rem;
      border-radius: 2rem;
      border: none;
      background: #121212;

      cursor: pointer;
      a {
        // width: 100%;
        padding: inherit;
        color: #fff;
      }
    }
  }
  // &:hover {
  //   transition: all 300ms ease-in;
  //   .button {
  //     display: block;
  //   }
  // }
  @media (max-width: 768px) {
    flex: 0 0 70vw;
    img {
      width: 60vw;
      left: 40%;
    }
    .detail {
      h3 {
        font-size: 1rem;
        &:nth-child(2) {
          font-size: 1.2rem;
          color: #8000dd;
        }
      }
    }
    .capacity {
      font-size: 1rem;
    }
    .button {
      width: 100%;
      button {
        width: 70%;
        padding: 0.5rem 1.5rem;
        font-size: 1rem;
        color: #fff;
      }
    }
  }
}
